import Logo from "../../assets/Logo";
import { Link } from "react-router-dom";
import Francisco from "../../assets/Images/francisco.png";

const ContactInfo = () => {
  return (
    <footer className=" max-w-full footer p-10 bg-neutral text-neutral-content items-center">
      <div className="flex flex-col">
        <Link to="/">
          <Logo />
          <p>
            Bringing the soulful sounds of Mexico to your special events since
            2019.
          </p>
        </Link>
      </div>
      <div>
        <span className="footer-title">Contact us</span>
        <div className="flex flex-col">
          <div className="flex flex-row">
            <div className="avatar hidden md:block mr-3">
              <div className="w-20 mask mask-circle">
                <img
                  src={Francisco}
                  alt="Francisco Navarrete from Mariachi Artistico ( Mariachi band in NY, NJ, and CT )"
                />
              </div>
            </div>
            <div>
              <div>
                <a className="link" href="tel: 914-912-1497">
                  Phone: 914•912•1497
                </a>
              </div>
              <div>
                <a className="link" href="mailto: fnavar2701@aol.com">
                  Email: fnavar2701@aol.com
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default ContactInfo;
