import { aboutUs } from "../../assets/Text/AboutUs";
import Stamford from "../../assets/Images/stamford.jpg";
import { Helmet } from "react-helmet";

const AboutContainer = () => (
  <div className="hero bg-neutral-focus">
    <div className="hero-content flex-col lg:flex-row-reverse bg-secondary p-10 rounded-3xl mb-10 mt-5 text-justify">
      <img
        src={Stamford}
        className=" rounded-lg shadow-2xl lg:w-30 mx-5"
        alt="Woman dancing to Mariachi Artistico in Stamford, CT"
      />
      <div>
        <h1 className="text-5xl font-bold">Get to know us</h1>
        {aboutUs.map((p, i) => (
          <p className="pt-6" key={i}>
            {p}
          </p>
        ))}
      </div>
    </div>
  </div>
);

const About = () => (
  <>
    <Helmet>
      <title>About us - Mariachi Artistico</title>
      <meta
        title="About Mariachi Artistico"
        content="Learn about Mariachi Artistico, the highest-rated mariachi band in New York, New Jersey, and Connecticut. Our family-owned business has been performing traditional mariachi music for four generations, blending original sounds with a modern twist. Discover our passion for music, professionalism, and commitment to making your event unforgettable."
      />
    </Helmet>
    <AboutContainer />
  </>
);

export default About;
