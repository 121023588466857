import Hero from "../../components/Home/Hero";
import Reviews from "../../components/Home/Reviews";
import { Helmet } from "react-helmet";

const Home = () => {
  return (
    <>
      <Helmet>
        <title>
          Mariachi Artistico - The highest rated mariachi band in New York, New
          Jersey, and Connecticut
        </title>
        <meta
          name="Mariachi Artistico"
          content="Welcome to Mariachi Artistico - the highest rated mariachi band in the tri-state area of New York, New Jersey, and Connecticut. We specialize in providing authentic Mexican music for all types of events, including weddings, corporate events, quinceañeras, and more. Our talented musicians have been entertaining audiences for over four generations, bringing the soulful sounds of Mexico to life. Book us for your next event and experience the magic of Mariachi Artistico."
        />
      </Helmet>
      <Hero />
      <Reviews />
    </>
  );
};

export default Home;
