import { ReactElement, JSXElementConstructor } from "react";

const ToolTip = (props: {
  children: ReactElement<any, string | JSXElementConstructor<any>>;
  text: String;
}) => {
  return (
    <div className="tooltip" data-tip={props.text}>
      {props.children}
    </div>
  );
};

export default ToolTip;
