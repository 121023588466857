import ButtonGroup from "../ButtonGroup";

const Hero = () => {
  return (
    <div className="hero min-h-screen bg-hero-banner">
      <div className="hero-overlay bg-opacity-70 backdrop-blur-sm bg-neutral-focus"></div>
      <div className="hero-content text-center text-neutral-content">
        <div className="max-w-md">
          <h1 className="mb-5 text-5xl font-bold">
            The Highest Rated Mariachi Band in New York
          </h1>
          <p className="mb-5 text-primary">
            Bringing Latin culture to your birthdays, wedding, or corporate
            events
          </p>
          <ButtonGroup />
          <a href="sms:+1-914-912-1497" className="btn btn-primary mx-1">
            book now
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.gigmasters.com/mariachi-band/mariachi-artistico/feedback"
            className="btn btn-primary mx-1"
          >
            Read our reviews
          </a>
        </div>
      </div>
    </div>
  );
};

export default Hero;
