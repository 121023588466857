import Music from "../../../assets/Icons/Music";
import Insta from "../../../assets/Icons/Insta";
import Youtube from "../../../assets/Icons/Youtube";
import ToolTip from "../../ToolTip";

const ButtonGroup = () => {
  return (
    <div className="space-x-2 m-4">
      <ToolTip text="Listen to us on Apple Music">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://music.apple.com/us/artist/francisco-navarrete-y-su-mariachi-artistico/1459679384"
          className="btn btn-circle btn-outline"
        >
          <Music />
        </a>
      </ToolTip>
      <ToolTip text="Follow us on Instagram">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.instagram.com/fran_mariachi/"
          className="btn btn-circle btn-outline"
        >
          <Insta />
        </a>
      </ToolTip>
      <ToolTip text="Watch us on YouTube">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.youtube.com/results?search_query=mariachi+artistico"
          className="btn btn-circle btn-outline"
        >
          <Youtube />
        </a>
      </ToolTip>
    </div>
  );
};

export default ButtonGroup;
