import Phone from "../../../assets/Icons/Phone";

const ContactIcons = () => (
  <>
    <div>
      <Phone />
      <a href="tel: 9149121497">(914) 912-1497</a>
    </div>
  </>
);

export default ContactIcons;
