import { Review } from "../types";

export const reviews: Review[] = [
  {
    review:
      "Mariachi Artistico was FANTASTIC. They were just what we wanted. The were prompt, courteous, extremely talented, obliging. We were so happy to have used them and everyone at out venue loved them. They added so much to our party.",
    name: "Howard A.",
    id: 0,
    score: 5,
    eventType: "Private party",
  },
  {
    review:
      "ABSOLUTELY AMAZING!! I would hire them again in a heartbeat. They were the highlight of my husbands retirement party. Everyone is still talking about how great they were. Incredible in every way!!!",
    name: "Voula C.",
    id: 1,
    score: 5,
    eventType: "Retirement Party",
  },
  {
    review:
      "They played a variety of requested songs and engaged the guests. Francisco was very friendly and a great MC.",
    name: "Rocio S.",
    id: 2,
    score: 5,
    eventType: "First Communion",
  },
  {
    review:
      "I loved Mariachi Artistico, they came and were very professional, everyone loved them. The price was good too. Would defiantly hire them again for all my parties. Gave their number to at least 3 different people... that how much people enjoyed them",
    name: "Patricia A.",
    score: 5,
    id: 3,
    eventType: "Birthday Party",
  },
  {
    review:
      "Mariachi Aristico will make any event an experience. They are professional, patient, courteous.  I wish I could give more stars. I will use them again for ANY occasion. I thank them for making our party memorable and for extending time when they didn't have to.",
    name: "Michelle L.",
    score: 5,
    id: 4,
    eventType: "Private party",
  },
  {
    review:
      "Mariachi Artistico was the best choice we made for our event! They were prompt, courteous and professional, and best of all, incredibly talented and engaging. Their music and voices were so enjoyable and song choices were all crowd-pleasers. I would recommend them highly to my friends and family--but wouldn't need to! Everyone there saw and heard for themselves!!",
    name: "Debra K.",
    score: 5,
    id: 5,
    eventType: "Graduation Party",
  },
];
