import { Review } from "../../../types";
import { reviews } from "../../../assets/Reviews";

type ReviewProp = {
  review: Review;
};

const ReviewCard = (props: ReviewProp) => (
  <>
    <div className="card w-96 bg-secondary shadow-xl text-secondary-content m-3">
      <div className="card-body">
        <h2 className="card-title text-primary">{props.review.eventType}</h2>
        <p>
          "{props.review.review} " -{props.review.name}
        </p>
        <div className="card-actions justify-end text-transparent">
          <div className="mask mask-star-2 bg-primary">⭐</div>
          <div className="mask mask-star-2 bg-primary">⭐</div>
          <div className="mask mask-star-2 bg-primary">⭐</div>
          <div className="mask mask-star-2 bg-primary">⭐</div>
          <div className="mask mask-star-2 bg-primary">⭐</div>
        </div>
      </div>
    </div>
  </>
);

const ReviewContainer = () => {
  return (
    <div className="w-full mb-5">
      <div className="carousel rounded-box">
        {reviews.map((r, i) => (
          <div className="carousel-item" key={i}>
            <ReviewCard review={r} />
          </div>
        ))}
      </div>
    </div>
  );
};

const Reviews = () => (
  <div className="bg-neutral-focus pb-5 pt-10">
    <div className="pb-2 px-8">
      <h1 className="text-4xl font-bold">
        Don't just take our word for it - hear what our clients have to say!
      </h1>
      <h2>
        With over 100 verified five-star reviews on The Bash, Mariachi Artistico
        is the clear choice for exceptional live music that adds a touch of
        magic to any occasion.
      </h2>
    </div>
    <ReviewContainer />
  </div>
);

export default Reviews;
