import { intro, serviceList, outro } from "../../assets/Text/Services";
import { Service } from "../../types";
import { Helmet } from "react-helmet";

type ServiceProp = {
  service: Service;
};

const ServiceItem = ({ service }: ServiceProp) => (
  <div className="card w-96 bg-primary text-primary-content m-2 shadow-xl">
    <div className="card-body">
      <h2 className="font-bold text-lg text-center">{service.name}</h2>
      <p>{service.description}</p>
    </div>
  </div>
);

const ServiceContainer = () => (
  <div className="flex flex-wrap justify-center text-center">
    {serviceList.map((s, i) => (
      <ServiceItem key={i} service={s} />
    ))}
  </div>
);

const ServicesComponents = () => (
  <div className="bg-neutral-focus pb-10 pt-5">
    <div className="p-10 rounded-3xl mb-10 mt-5 bg-accent m-4">
      <h1 className="text-5xl font-bold text-center">Our Services</h1>
      <h2 className="text-2xl text-center">
        Elevate Your Event with Mariachi Artistico's Entertainment Services
      </h2>
      <div className="mt-1">
        <p className="text-lg mb-3 lg:mx-10 lg:text-justify">{intro}</p>
        <ServiceContainer />
        <p className="text-lg mt-3 lg:mx-10">{outro}</p>
      </div>
    </div>
  </div>
);

const Services = () => (
  <>
    <Helmet>
      <title>Services in NY, NJ, and CT- Mariachi Artistico</title>
      <meta
        title="Services in NY, NJ, and CT - Mariachi Artistico"
        content="We recognize that not all events fit into a predefined category, which is why we offer services for a variety of other occasions. These include but are not limited to quinceañeras, anniversary celebrations, cultural festivals, and more."
      />
    </Helmet>
    <ServicesComponents />
  </>
);

export default Services;
