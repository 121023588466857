import { Service } from "../../types";

export const intro = `At Mariachi Artistico, we provide a range of entertainment services to help you celebrate life's most important moments. Our experienced musicians are skilled at performing a wide variety of musical genres, including traditional mariachi, norteño, and jazz. We're committed to bringing our unique sound and energy to every event we perform at, from intimate gatherings to large-scale corporate events. Based in the tri-state area, we proudly offer our services in New York, New Jersey, and Connecticut.`;

export const serviceList: Service[] = [
  {
    name: "Birthday Parties",
    description: `What better way to celebrate a birthday than with the festive sounds of mariachi music? Our musicians will get everyone up and dancing with our upbeat, lively tunes, making your party a fun and unforgettable experience.`,
  },
  {
    name: `Weddings`,
    description: `Mariachi music is a staple at weddings, and our talented musicians will help create a romantic, joyful atmosphere that will make your special day even more memorable. Whether you want a traditional ceremony or a lively reception, we have the perfect music to suit your tastes.`,
  },
  {
    name: `Engagements`,
    description: `We're also available to provide music for engagements, whether you're planning a surprise proposal or a celebration with family and friends. Our romantic, traditional mariachi music is the perfect accompaniment to this special moment.`,
  },
  {
    name: "Corporate Events",
    description: `From awards ceremonies to holiday parties, Mariachi Artistico is the perfect entertainment choice for your next corporate event. We'll provide a sophisticated, professional touch to your event, creating a memorable experience for your employees, clients, or customers.`,
  },
  {
    name: "Funerals",
    description: `We understand that this is a difficult time for families and loved ones, and we're honored to be able to provide our music to help celebrate the life of the departed. Our musicians are respectful, professional, and always mindful of the emotional needs of those in attendance.`,
  },
  {
    name: "Other",
    description: `We recognize that not all events fit into a predefined category, which is why we offer services for a variety of other occasions. These include but are not limited to quinceañeras, anniversary celebrations, cultural festivals, and more.`,
  },
];

export const outro = `No matter what kind of event you're planning, Mariachi Artistico has the perfect music to suit your needs. Contact us today to learn more about our services and how we can make your event an unforgettable one.`;
